
import { defineComponent, ref, onMounted, nextTick } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import { PasswordMeterComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "ResetPassword",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const submitButton = ref<HTMLButtonElement | null>(null);

    //Create form validation object
    const resetPassword = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
      token: Yup.string().required().label("Reset Token"),
      password: Yup.string()
        .min(6)
        .max(15)
        .required()
        .matches(
          /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/,
          "Please choose a stronger password"
        )
        .label("Password"),
      password_confirmation: Yup.string()
        .required()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .label("Password Confirmation"),
    });

    onMounted(() => {
      if (!route.query.token) {
        router.push({ name: "signIn" });
      }
      nextTick(() => {
        PasswordMeterComponent.bootstrap();
      });
    });

    //Form submit function
    const onSubmitResetPassword = (values) => {
      // eslint-disable-next-line
      submitButton.value!.disabled = true;
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // Send login request
      store
        .dispatch(Actions.RESET_PASSWORD, values)
        .then(() => {
          Swal.fire({
            text: "Your Password have been reset successfully! Please Sign In.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            // Go to page after successfully login
            router.push({ name: "signIn" });
          });
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getErrors);
          // Alert then login failed
          Swal.fire({
            text: store.getters.getErrors[error],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        })
        .finally(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        });
    };

    const formValues = {
      token: route.query.token,
    };

    return {
      onSubmitResetPassword,
      resetPassword,
      submitButton,
      formValues,
    };
  },
});
